import { listEmptyName, listItemName, listItemNameCopy } from "./index";

export const listHandler = (list: HTMLElement) => {
  const listEmpty = list.querySelector(
    `:scope > [id*="${listEmptyName}"]`
  ) as HTMLElement | null;
  if (listEmpty) {
    const listItems = Array.from(
      list.querySelectorAll(
        `:scope > [id*="${listItemName}"], :scope > [id*="${listItemNameCopy}"]`
      )
    ) as HTMLElement[];
    if (listItems.length) {
      listEmpty.hidden = true;
    } else {
      listEmpty.removeAttribute("hidden");
    }
  }
};
