import slugify from "react-slugify";

import { GetVariableValue } from "../hooks";
import {
  BarComponentType,
  BrandingColorStyles,
  BrandingColorType,
  BrandingThemeType,
  Resource,
  TabBarItem,
  getMediaResourceUrl,
  getTextValue,
} from "./index";

export const createScreenTabBar = (
  id: string,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  screen: HTMLElement,
  height: number
) => {
  const screenTabBar = document.createElement("div");
  screenTabBar.id = id;
  screenTabBar.style.position = "fixed";
  screenTabBar.style.left = "0";
  screenTabBar.style.bottom = "0";
  screenTabBar.style.zIndex = "2";
  screenTabBar.style.background = colorStyles.background[theme];
  screenTabBar.style.width = "100%";
  screenTabBar.style.height = `${height}px`;
  screenTabBar.style.padding = "5px 0";
  screenTabBar.style.boxSizing = "border-box";
  screenTabBar.style.display = "flex";
  screenTabBar.style.justifyContent = "space-around";
  screenTabBar.style.alignItems = "flex-start";
  screenTabBar.style.backgroundBlendMode = "hard-light";
  screenTabBar.style.borderWidth = "0";
  screenTabBar.style.borderStyle = "solid";
  screenTabBar.style.borderColor = colorStyles.surfaceVariant[theme];
  screenTabBar.style.borderTopWidth = "1px";
  screen.appendChild(screenTabBar);
  return screenTabBar;
};

export const setTabs = async (
  screenName: string,
  tabs: TabBarItem[],
  screenTabBar: HTMLElement,
  fontFamily: string,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  desktopMode: boolean,
  navigate: (pathname: string, search?: string) => void,
  language: string,
  getVariableValue: GetVariableValue
) => {
  const visibleTabs: TabBarItem[] = [];
  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i];
    const { visible } = tab;
    if (
      visible.source
        ? (await getVariableValue(visible.source)) === "true"
        : visible.constant
    ) {
      visibleTabs.push(tab);
    }
  }
  for (const el of visibleTabs) {
    const { image, title, screen } = el;
    const isActive = screen === screenName;
    const tab = document.createElement("div");
    tab.id = `${BarComponentType.tab}.${screen}.${screen}`;
    tab.style.position = "relative";
    tab.style.display = "flex";
    tab.style.flexDirection = "column";
    tab.style.alignItems = "center";
    tab.style.gap = "4px";
    tab.style.width = `calc(100% / ${tabs.length})`;

    if (desktopMode && isActive) {
      const shadow = document.createElement("div");
      shadow.style.position = "absolute";
      shadow.style.top = "-8px";
      shadow.style.zIndex = "-1";
      shadow.style.width = "40px";
      shadow.style.height = "40px";
      shadow.style.borderRadius = "8px";
      shadow.style.background = `${colorStyles.onBackground[theme]}26`;
      tab.appendChild(shadow);
    }

    const width = 24;
    const imageElement = document.createElement("img");
    imageElement.style.width = `${width}px`;
    imageElement.style.height = `${width}px`;
    imageElement.style.maxHeight = "100%";
    imageElement.style.objectFit = "contain";
    const refresh = async () => {
      const url = await getMediaResourceUrl(
        resources,
        width * 3,
        getVariableValue,
        refresh,
        image,
        {
          constant: desktopMode
            ? `@${BrandingColorType.onBackground}`
            : isActive
            ? `@${BrandingColorType.primary}`
            : `@${BrandingColorType.neutral}`,
        },
        theme,
        colorStyles
      );
      imageElement.setAttribute("src", url);
    };
    await refresh();
    tab.appendChild(imageElement);

    const textElement = document.createElement("div");
    textElement.style.fontFamily = fontFamily;
    textElement.style.lineHeight = "12px";
    textElement.style.fontSize = "10px";
    textElement.style.textAlign = "center";
    textElement.style.color = desktopMode
      ? colorStyles.onBackground[theme]
      : isActive
      ? colorStyles.primary[theme]
      : colorStyles.neutral[theme];
    textElement.innerHTML = await getTextValue(
      language,
      getVariableValue,
      refresh,
      title
    );
    tab.appendChild(textElement);

    screenTabBar.appendChild(tab);

    tab.className = "clickable";
    tab.onclick = () => navigate(`/${slugify(screen)}`);
  }
};
