import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import slugify from "react-slugify";

import { FirebaseContext } from "./context";
import { useVariable } from "./hooks";
import {
  BrandingThemeType,
  Config,
  Screen,
  getInputParameters,
  getScreen,
} from "./utils";

export const rootElement = document.getElementById("root") as HTMLElement;

interface Props {
  inDevice: boolean;
  language: string;
  theme: BrandingThemeType;
  desktopMode: boolean;
  wideDesktopMode: boolean;
  config: Config;
  screens: Screen[];
  launchScreen: Screen;
  paywallScreen: Screen;
  email?: string;
  token?: string;
}

export const Page: React.FC<Props> = ({
  inDevice,
  language,
  theme,
  desktopMode,
  wideDesktopMode,
  config,
  screens,
  launchScreen,
  paywallScreen,
  email,
  token,
}) => {
  const { f } = useContext(FirebaseContext);

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [launchOpen, setLaunchOpen] = useState(true);
  const [paywallOpen, setPaywallOpen] = useState(false);

  const pages = (params["*"] as string).split("/");
  const page = pages[pages.length - 1];

  const originalScreen = useMemo(
    () => screens.find((el) => slugify(el.screenName) === page),
    [page]
  );

  const screen = useMemo(
    () =>
      launchOpen ? launchScreen : paywallOpen ? paywallScreen : originalScreen,
    [launchOpen, paywallOpen, originalScreen]
  );

  const leadingPaneScreenName = screen?.experimental?.leadingPane?.screenName;
  const leadingPaneScreen = useMemo(
    () =>
      wideDesktopMode &&
      screens.find((el) => el.screenName === leadingPaneScreenName),
    [wideDesktopMode, leadingPaneScreenName]
  );

  const trailingPaneScreenName = screen?.experimental?.trailingPane?.screenName;
  const trailingPaneScreen = useMemo(
    () =>
      wideDesktopMode &&
      screens.find((el) => el.screenName === trailingPaneScreenName),
    [wideDesktopMode, trailingPaneScreenName]
  );

  const { inputParameter } = getInputParameters(screen);
  const inputParameterValue = !!inputParameter
    ? pages[pages.length - 2]
    : undefined;

  const { getVariable, update } = useVariable(
    f,
    config,
    language,
    searchParams,
    inputParameterValue,
    screen
  );

  const goBack = (search?: string) =>
    navigate({
      pathname: `/${pages.slice(0, !!inputParameter ? -2 : -1).join("/")}`,
      search,
    });

  const generateScreen = async () => {
    if (leadingPaneScreen) {
      await getScreen(
        inDevice,
        f,
        leadingPaneScreen,
        config,
        rootElement,
        desktopMode,
        language,
        theme,
        launchOpen,
        setLaunchOpen,
        searchParams,
        setSearchParams,
        (pathname, search) =>
          pathname.startsWith("/")
            ? navigate({ pathname, search })
            : navigate({
                pathname: `/${pages
                  .slice(0, !!inputParameter ? -2 : -1)
                  .join("/")}/${pathname}`,
                search,
              }),
        goBack,
        setPaywallOpen,
        getVariable,
        update,
        email,
        token,
        true
      );
    }
    if (screen) {
      await getScreen(
        inDevice,
        f,
        screen,
        config,
        rootElement,
        desktopMode,
        language,
        theme,
        launchOpen,
        setLaunchOpen,
        searchParams,
        setSearchParams,
        (pathname, search) => navigate({ pathname, search }),
        goBack,
        setPaywallOpen,
        getVariable,
        update,
        email,
        token,
        !!trailingPaneScreen,
        !!leadingPaneScreen,
        inputParameterValue
      );
    } else {
      // 404
    }
    if (trailingPaneScreen) {
      await getScreen(
        inDevice,
        f,
        trailingPaneScreen,
        config,
        rootElement,
        desktopMode,
        language,
        theme,
        launchOpen,
        setLaunchOpen,
        searchParams,
        setSearchParams,
        (pathname, search) => navigate({ pathname, search }),
        goBack,
        setPaywallOpen,
        getVariable,
        update,
        email,
        token,
        false,
        true
      );
    }
  };

  useEffect(() => {
    generateScreen();
  }, [
    inDevice,
    leadingPaneScreen,
    screen,
    trailingPaneScreen,
    searchParams,
    inputParameterValue,
    language,
    theme,
    desktopMode,
  ]);

  return <></>;
};
