import { SetURLSearchParams } from "react-router-dom";
import slugify from "react-slugify";

import { F } from "../context";
import {
  collectionPrefix,
  getRecordId,
  GetVariableValue,
  Update,
} from "../hooks";
import {
  checkIdfaAndCookieTrackingEnabled,
  checkNotificationsEnabled,
  enableIdfaAndCookieTracking,
  enableNotifications,
} from "../services/permissions";
import { checkoutSelected } from "../services/stripe";
import {
  Action,
  ActionType,
  getInputParameters,
  getSearch,
  getSearchParams,
  readableError,
  Screen,
} from "./index";

export const runActions = async (
  actions: Action[],
  f: F,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  navigate: (pathname: string, search?: string) => void,
  goBack: (search?: string) => void,
  setPaywallOpen: React.Dispatch<React.SetStateAction<boolean>>,
  screens: Screen[],
  screenConfig: Screen,
  getVariableValue: GetVariableValue,
  update: Update
) => {
  const {
    auth,
    signInWithGoogle,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithPhoneNumber,
    reSignInWithPhoneNumber,
    confirmPhoneNumber,
    sendSignInLinkToEmail,
    reSendSignInLinkToEmail,
  } = f;
  try {
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      const {
        actionType,
        options,
        componentName,
        parameters,
        variable,
        variableContextKey,
        record,
        subActions,
        fallbackActions,
      } = action;
      const screen = options?.screen;
      const link = options?.link;
      const email = options?.email;
      if (
        (actionType === ActionType.check &&
          variable?.source &&
          (await getVariableValue(
            variable.source,
            undefined,
            undefined,
            variableContextKey
          )) === "false") ||
        (actionType === ActionType.checkNotificationsEnabled &&
          !checkNotificationsEnabled()) ||
        (actionType === ActionType.checkIdfaAndCookieTrackingEnabled &&
          !checkIdfaAndCookieTrackingEnabled())
      ) {
        if (fallbackActions) {
          await runActions(
            fallbackActions,
            f,
            searchParams,
            setSearchParams,
            navigate,
            goBack,
            setPaywallOpen,
            screens,
            screenConfig,
            getVariableValue,
            update
          );
        }
        break;
      } else if (actionType === ActionType.handleDeeplink) {
        const { pathname, search } = window.location;
        if (
          pathname !== "/" &&
          pathname !== `/${slugify(screenConfig.screenName)}`
        ) {
          navigate(pathname, search);
        } else {
          if (fallbackActions) {
            await runActions(
              fallbackActions,
              f,
              searchParams,
              setSearchParams,
              navigate,
              goBack,
              setPaywallOpen,
              screens,
              screenConfig,
              getVariableValue,
              update
            );
          }
        }
        break;
      } else if (actionType === ActionType.subscribe) {
        const subscribe = async () => {
          if (variable?.source) {
            await getVariableValue(
              variable.source,
              subscribe,
              undefined,
              variableContextKey
            );
          }
          if (subActions) {
            for (const el of subActions) {
              if (el.actionType === ActionType.updateRecord) {
                await update(el, getVariableValue);
              }
            }
          }
        };
        await subscribe();
      } else if (actionType === ActionType.waitProfile && record) {
        const checkReady = async () =>
          (await getVariableValue(record)) === "true";
        let ready = await checkReady();
        while (!ready) {
          await new Promise((r) => setTimeout(r, 1000));
          ready = await checkReady();
        }
      } else if (actionType === ActionType.show && screen) {
        navigate(`/${slugify(screen)}`);
        break;
      } else if (actionType === ActionType.navigate && screen) {
        if (parameters) {
          const { inputParameter, searchInputParameters } = getInputParameters(
            screens.find((el) => el.screenName === screen)
          );
          if (inputParameter) {
            const parameter = parameters.find(
              (el) => inputParameter.parameter === el.parameter
            );
            if (parameter && parameter.getter?.source) {
              const value = await getVariableValue(parameter.getter?.source);
              const isRecordId = value.includes(collectionPrefix);
              navigate(
                `${isRecordId ? getRecordId(value) : value}/${slugify(screen)}`
              );
            }
          } else if (searchInputParameters) {
            const params = await getSearchParams(
              parameters,
              searchInputParameters,
              getVariableValue
            );
            if (screenConfig.screenName === screen) {
              for (const el of params) {
                const { name, value } = el;
                if (value) {
                  searchParams.set(name, value);
                } else {
                  searchParams.delete(name);
                }
              }
              setSearchParams(searchParams);
            } else {
              navigate(slugify(screen), getSearch(params));
            }
          }
        } else {
          navigate(slugify(screen));
        }
        break;
      } else if (actionType === ActionType.navigateBack) {
        if (screen && parameters) {
          const { searchInputParameters } = getInputParameters(
            screens.find((el) => el.screenName === screen)
          );
          if (searchInputParameters) {
            const params = await getSearchParams(
              parameters,
              searchInputParameters,
              getVariableValue
            );
            goBack(getSearch(params));
          }
        } else {
          goBack();
        }
        break;
      } else if (
        actionType === ActionType.getPhoto ||
        actionType === ActionType.getAudio ||
        actionType === ActionType.getVideo ||
        actionType === ActionType.getFile ||
        actionType === ActionType.setValue ||
        actionType === ActionType.createRecord ||
        actionType === ActionType.updateRecord
      ) {
        await update(action, getVariableValue);
      } else if (actionType === ActionType.logout) {
        await auth.signOut();
      } else if (actionType === ActionType.anonymousAuthentication) {
        await auth.signInAnonymously();
      } else if (actionType === ActionType.googleAuthentication) {
        await signInWithGoogle();
      } else if (actionType === ActionType.createUserWithEmailAndPassword) {
        await createUserWithEmailAndPassword(action, getVariableValue);
      } else if (actionType === ActionType.signInWithEmailAndPassword) {
        await signInWithEmailAndPassword(action, getVariableValue);
      } else if (actionType === ActionType.sendPasswordResetEmail) {
        await sendPasswordResetEmail(action, getVariableValue);
      } else if (actionType === ActionType.signInWithPhoneNumber) {
        await signInWithPhoneNumber(action, getVariableValue);
      } else if (actionType === ActionType.reSignInWithPhoneNumber) {
        await reSignInWithPhoneNumber(action, getVariableValue);
      } else if (actionType === ActionType.confirmPhoneNumber) {
        await confirmPhoneNumber(action, getVariableValue);
      } else if (actionType === ActionType.sendSignInLinkToEmail) {
        await sendSignInLinkToEmail(action, getVariableValue);
      } else if (actionType === ActionType.reSendSignInLinkToEmail) {
        await reSendSignInLinkToEmail(action, getVariableValue);
      } else if (actionType === ActionType.openEmailApp) {
        window.open("https://gmail.com");
      } else if (actionType === ActionType.enableNotifications) {
        await enableNotifications();
      } else if (actionType === ActionType.enableIdfaAndCookieTracking) {
        enableIdfaAndCookieTracking();
      } else if (
        (actionType === ActionType.openLink ||
          actionType === ActionType.openWeb) &&
        link
      ) {
        window.open(
          link.source ? await getVariableValue(link.source) : link.constant
        );
      } else if (actionType === ActionType.openEmail && email) {
        window.location.href = `mailto:${options.email}`;
      } else if (actionType === ActionType.checkoutSelected) {
        await checkoutSelected(auth, getVariableValue);
      } else if (actionType === ActionType.closePurchases) {
        setPaywallOpen(false);
        if (actions[i - 1]?.actionType !== ActionType.checkoutSelected) {
          goBack();
          break;
        }
      } else if (actionType === ActionType.scrollToStart && componentName) {
        const elementToScroll = document.querySelector(
          `[id*=".${componentName}."]`
        );
        elementToScroll?.scrollTo(
          elementToScroll.scrollWidth,
          elementToScroll.scrollHeight
        );
      }
    }
  } catch (err: any) {
    alert(readableError(err.message));
  }
};
