import firebase from "firebase/compat/app";
import { marked } from "marked";
import { useEffect } from "react";
import { format } from "timeago.js";

import { F } from "../context";
import {
  Action,
  ActionType,
  AnyVariablTypeKey,
  AnyVariable,
  Collection,
  CollectionProperty,
  CollectionVariable,
  Config,
  LocalVariable,
  LocalizedContent,
  RecordModification,
  Screen,
  ScreenComponent,
  ScreenParameter,
  StringVariable,
  TableQuery,
  TableQueryOrder,
  ValueType,
  VariableSource,
  VariableSourceType,
  VariableTransform,
  VariableTransformTransform,
  findComponent,
  getTextValue,
  needOrderByOperators,
  operators,
  readableError,
} from "../utils";

export type GetVariable = (
  screenConfig: Screen,
  listId?: string,
  indexInList?: number
) => GetVariableValue;

export type GetVariableValue = (
  source: VariableSource,
  refresh?: () => void,
  listParams?: {
    listId: string;
    listItemContextKey: string;
    limit?: number;
  },
  localVariableName?: string,
  valueToSet?: CollectionWithRecords | string
) => Promise<string>;

export type Update = (
  action: Action,
  getVariableValue: GetVariableValue
) => Promise<void>;

interface CMSCollectionRecord {
  [key: string]: any;
}

interface CollectionWithRecords extends Collection {
  records: CMSCollectionRecord[];
  limit?: number;
}
let variables: {
  [key: string]: CollectionWithRecords | string;
} = {};
let firebaseDatasources: { [key: string]: CMSCollectionRecord } = {};
const snapshots: { [key: string]: () => void } = {};
const refreshes: { [key: string]: (() => void)[] } = {};

const addRefresh = (name: string, refresh?: () => void) =>
  refresh && (refreshes[name] = [...(refreshes[name] || []), refresh]);

const runRefreshes = (name: string) => {
  if (refreshes[name]) {
    let length = refreshes[name].length;
    for (let i = 0; i < length; i++) {
      const refresh = refreshes[name][i];
      refreshes[name].splice(i, 1);
      i--;
      length--;
      refresh();
    }
  }
};

export const useVariable = (
  f: F,
  config: Config,
  language: string,
  searchParams: URLSearchParams,
  inputParameterValue?: string,
  screen?: Screen
) => {
  const {
    data: { collections = [], globalVariables = [] },
    branding: {
      appName,
      icons: { iosIcon },
    },
    resources = [],
  } = config;
  const applicationName = appName.locales[language] || "";
  const applicationIcon = resources.find((el) => el.id === iosIcon)?.url || "";
  const { firestorePrefix, auth, firestore, getAssetRecord } = f;

  const getVariable: GetVariable = (screenConfig, listId, indexInList) => {
    const getVariableValue: GetVariableValue = async (
      source,
      refresh,
      listParams,
      localVariableName,
      valueToSet
    ) => {
      const {
        type,
        collection,
        fieldName,
        selector,
        query,
        transforms,
        variableName,
        componentName,
      } = source;
      const getVariableValue = getVariable(screenConfig, listId, indexInList);
      const screenId = screenConfig.id;
      switch (type) {
        case VariableSourceType.globalVariable:
          let value = "";
          const globalVariable = globalVariables.find(
            (el) => el.variableName === variableName
          );
          switch (variableName) {
            case "currentUserId":
              value = auth.currentUser?.uid || "";
              break;
            case "applicationVersion":
              value = "v.1.0.1 (1)";
              break;
            case "applicationName":
              value = applicationName;
              break;
            case "applicationIcon":
              value = applicationIcon;
              break;
            case "isSignedOut":
              value = String(!auth.currentUser);
              break;
            case "isAnonymous":
              value = String(!!auth.currentUser?.isAnonymous);
              break;
            case "hasUserAccount":
              value = String(
                !!auth.currentUser && !auth.currentUser.isAnonymous
              );
              break;
            case "isEmailVerified":
              value = String(!!auth.currentUser?.emailVerified);
              break;
            case "accessLevels":
              if (globalVariable?.variable.source) {
                value = await getVariableValue(
                  globalVariable.variable.source,
                  refresh
                );
              }
              break;
          }
          return convertFieldToString(
            value,
            { name: "", type: ValueType.string, position: 0 },
            getVariableValue,
            undefined,
            transforms
          );
        case VariableSourceType.collection:
          if (collection) {
            const collectionId = await getCurrentCollectionId(
              collection,
              getVariableValue,
              refresh
            );
            const collectionData = collections.find(
              (el) => el.name === collection.name
            );
            if (collectionData) {
              const property = collectionData.properties?.find(
                (el) => el.name === fieldName
              );
              const recordId = selector?.source
                ? await getVariableValue(selector.source, refresh)
                : selector?.constant;
              if (
                listParams ||
                (localVariableName &&
                  !(listId && localVariableName && indexInList !== undefined))
              ) {
                const limit = listParams?.limit;
                const name = listParams
                  ? screenId + listParams.listId + listParams.listItemContextKey
                  : screenId + localVariableName;
                addRefresh(name + (limit || ""), refresh);
                const variable = variables[name] as
                  | CollectionWithRecords
                  | undefined;
                if (
                  !variable ||
                  hasMutableFilter(query) ||
                  (limit && variable.limit !== limit)
                ) {
                  if (recordId) {
                    const firebaseDatasourceName = `${collectionId}/${recordId}`;
                    const set = async (record: CMSCollectionRecord) => {
                      firebaseDatasources[firebaseDatasourceName] = record;
                      if (fieldName && record[fieldName] && property) {
                        const subCollection =
                          property.type === ValueType.array &&
                          property.accept === ValueType.record &&
                          property.collection &&
                          collections.find(
                            (el) => el.name === property.collection
                          );
                        const assetsCollection =
                          (property.type === ValueType.image ||
                            property.type === ValueType.video ||
                            property.type === ValueType.audio ||
                            property.type === ValueType.file) &&
                          collections.find((el) => el.name === "assets");
                        if (subCollection) {
                          const recordsIds = record[fieldName].map(getRecordId);
                          if (recordsIds.length) {
                            const set = (records: CMSCollectionRecord[]) => {
                              variables[name] = {
                                ...subCollection,
                                records,
                                limit,
                              };
                            };
                            await getRecords(
                              name,
                              set,
                              subCollection.name,
                              getVariableValue,
                              query,
                              recordsIds,
                              transforms,
                              limit
                            );
                          }
                        } else if (assetsCollection) {
                          variables[name] = {
                            ...assetsCollection,
                            records: [record[fieldName]],
                          };
                        }
                      } else {
                        variables[name] = {
                          ...collectionData,
                          records: [record],
                        };
                      }
                    };
                    if (!firebaseDatasources[firebaseDatasourceName]) {
                      await getRecord(set, collectionId, recordId);
                    } else {
                      await set(firebaseDatasources[firebaseDatasourceName]);
                    }
                  } else if (localVariableName && !transforms) {
                    variables[name] = {
                      ...collectionData,
                      records: [{}],
                    };
                  } else {
                    const set = (records: CMSCollectionRecord[]) => {
                      variables[name] = {
                        ...collectionData,
                        records,
                        limit,
                      };
                    };
                    await getRecords(
                      name,
                      set,
                      collectionId,
                      getVariableValue,
                      query,
                      undefined,
                      transforms,
                      limit
                    );
                  }
                }
                return String(
                  (variables[name] as CollectionWithRecords)?.records.length ||
                    0
                );
              } else if (recordId && fieldName && property) {
                const record = await getFirebaseDatasourceRecord(
                  collectionId,
                  recordId,
                  refresh
                );
                const valueToReturn = await convertFieldToString(
                  record[fieldName],
                  property,
                  getVariableValue,
                  refresh,
                  transforms
                );
                if (listId && localVariableName && indexInList !== undefined) {
                  const name =
                    screenId + listId + localVariableName + indexInList;
                  variables[name] = valueToReturn;
                }
                return valueToReturn;
              } else if (recordId && transforms) {
                const record = await getFirebaseDatasourceRecord(
                  collectionId,
                  recordId,
                  refresh
                );
                return convertRecordToString(
                  record,
                  transforms,
                  getVariableValue,
                  refresh
                );
              } else if (recordId) {
                if (collectionId === "assets") {
                  const record = await getFirebaseDatasourceRecord(
                    collectionId,
                    recordId,
                    refresh
                  );
                  return JSON.stringify(record);
                } else {
                  return `${collectionPrefix}${collectionId}/${recordId}`;
                }
              } else if (transforms) {
                return convertRecordToString(
                  {},
                  transforms,
                  getVariableValue,
                  refresh
                );
              }
            }
          }
          return "";
        case VariableSourceType.localVariable:
          if (variableName) {
            if (valueToSet !== undefined) {
              const name = screenId + variableName;
              variables[name] = valueToSet;
              runRefreshes(name);
            }
            let refreshable = false;
            let variable;
            if (listId) {
              variable =
                variables[screenId + listId + variableName + indexInList] ||
                variables[screenId + listId + variableName];
            } else {
              const name = screenId + variableName;
              variable = variables[name];
              if (fieldName) {
                refreshable = true;
              } else {
                addRefresh(name, refresh);
              }
            }
            const value = typeof variable === "string" && variable;
            const collectionData = typeof variable !== "string" && variable;
            if (value || value === "") {
              return convertFieldToString(
                value,
                { name: "", type: ValueType.string, position: 0 },
                getVariableValue,
                undefined,
                transforms
              );
            } else if (collectionData) {
              const { name, records } = collectionData;
              const collectionId = name;
              const record = records[indexInList || 0] || {};
              const recordId = record.id as string | undefined;
              if (recordId) {
                const firebaseDatasourceName = `${collectionId}/${recordId}`;
                firebaseDatasources[firebaseDatasourceName] = record;
              }
              return getVariableValue(
                {
                  type: VariableSourceType.collection,
                  collection: { name: collectionId },
                  selector: { constant: recordId },
                  fieldName,
                  query,
                  transforms,
                },
                refreshable ? refresh : undefined,
                listParams,
                localVariableName,
                valueToSet
              );
            }
          }
          return "";
        case VariableSourceType.component:
          if (componentName) {
            const component = findComponent(
              screenConfig,
              "name",
              componentName
            );
            if (component) {
              let value = "";
              const fieldValue = fieldName
                ? component[fieldName as keyof ScreenComponent]
                : undefined;
              if (fieldValue && fieldName === "text") {
                const text = fieldValue as LocalizedContent;
                value = await getTextValue(
                  language,
                  getVariableValue,
                  refresh,
                  text
                );
              } else if (
                fieldValue &&
                (fieldName === "value" ||
                  fieldName === "date" ||
                  fieldName === "displayDate")
              ) {
                const date = fieldValue as StringVariable;
                value = date.source
                  ? await getVariableValue(date.source, refresh)
                  : date.constant || "";
              }
              const input: HTMLInputElement | null = document.querySelector(
                `[id*=".${componentName}."] > input`
              );
              if (input) {
                if (valueToSet !== undefined) {
                  input.value = valueToSet as string;
                  input.dispatchEvent(new Event("input"));
                }
                if (value !== input.value) {
                  value = input.value;
                }
              }
              const valueToReturn = await convertFieldToString(
                value,
                { name: "", type: ValueType.string, position: 0 },
                getVariableValue,
                refresh,
                transforms
              );
              if (localVariableName) {
                const name = screenId + localVariableName;
                variables[name] = valueToReturn;
                if (input) {
                  input.oninput = async () => {
                    await getVariableValue(
                      source,
                      refresh,
                      listParams,
                      localVariableName,
                      valueToSet
                    );
                    runRefreshes(name);
                  };
                }
              } else if (input && refresh) {
                const name = screenId + component.id + fieldName;
                addRefresh(name, refresh);
                input.oninput = () => runRefreshes(name);
              }
              return valueToReturn;
            }
          }
          return "";
        default:
          return "";
      }
    };
    return getVariableValue;
  };

  const getFirebaseDatasourceRecord = async (
    collectionId: string,
    recordId: string,
    refresh?: () => void
  ) => {
    const firebaseDatasourceName = `${collectionId}/${recordId}`;
    addRefresh(firebaseDatasourceName, refresh);
    if (!firebaseDatasources[firebaseDatasourceName]) {
      const set = (record: CMSCollectionRecord) => {
        firebaseDatasources[firebaseDatasourceName] = record;
      };
      await getRecord(set, collectionId, recordId);
    }
    return firebaseDatasources[firebaseDatasourceName];
  };

  const getRecord = (
    set: (record: CMSCollectionRecord) => void,
    collectionId: string,
    recordId: string
  ) =>
    new Promise((resolve) => {
      const firebaseDatasourceName = `${collectionId}/${recordId}`;
      let loaded = false;
      const setRecord = async (record: CMSCollectionRecord) => {
        await set(record);
        if (loaded) {
          runRefreshes(firebaseDatasourceName);
        }
        loaded = true;
        resolve(true);
      };
      snapshots[firebaseDatasourceName]?.();
      snapshots[firebaseDatasourceName] = firestore
        .collection(firestorePrefix + collectionId)
        .doc(recordId)
        .onSnapshot({
          next: (res) => {
            const data = res.data();
            setRecord(data ? { id: res.id, ...data } : {});
          },
          error: (err) => {
            alert(readableError(err.message));
            setRecord({});
          },
        });
    });

  const getRecords = (
    name: string,
    set: (records: CMSCollectionRecord[]) => void,
    collectionId: string,
    getVariableValue: GetVariableValue,
    query?: TableQuery,
    ids?: string[],
    transforms?: VariableTransform[],
    limit?: number
  ) =>
    new Promise(async (resolve) => {
      let loaded = false;
      const setRecords = async (records: CMSCollectionRecord[]) => {
        await set(records);
        if (loaded) {
          runRefreshes(name + (limit || ""));
        }
        loaded = true;
        resolve(true);
      };
      const firstTransform = transforms?.find(
        (el) => el.transform === VariableTransformTransform.first
      );
      let collectionQuery: firebase.firestore.Query;
      if (ids) {
        collectionQuery = firestore
          .collection(firestorePrefix + collectionId)
          .where("id", "in", ids);
      } else {
        collectionQuery = firestore.collection(firestorePrefix + collectionId);
      }
      if (query?.filters) {
        for (const el of query.filters) {
          const key = Object.keys(el.value)[0] as AnyVariablTypeKey;
          let value = el.value[key] as any;
          if (key === AnyVariablTypeKey.textConstant) {
            value = value.locales[language];
          } else if (key === AnyVariablTypeKey.source) {
            value = await getVariableValue(
              value,
              value.type === VariableSourceType.component
                ? () => runRefreshes(name + (limit || ""))
                : undefined
            );
            if (value && !isNaN(+value)) {
              value = +value;
            }
          }
          if (value !== "" && value !== undefined) {
            const opStr = operators[el.operator];
            if (needOrderByOperators.includes(opStr)) {
              collectionQuery = collectionQuery.orderBy(el.field, "desc");
            }
            collectionQuery = collectionQuery.where(
              el.field,
              opStr as firebase.firestore.WhereFilterOp,
              value
            );
          }
        }
      }
      collectionQuery = collectionQuery.orderBy(
        query?.orderedBy || "createdAt",
        query?.order === TableQueryOrder.ascending ? "asc" : "desc"
      );
      if (firstTransform) {
        collectionQuery = collectionQuery.limit(1);
      } else if (limit) {
        collectionQuery = collectionQuery.limit(limit);
      }
      snapshots[name]?.();
      snapshots[name] = collectionQuery.onSnapshot(
        { includeMetadataChanges: true },
        {
          next: (res) =>
            !res.metadata.fromCache &&
            !res.metadata.hasPendingWrites &&
            setRecords(
              res.docs.map((el) => ({ id: el.id, ...el.data() })) || []
            ),
          error: (err) => {
            alert(readableError(err.message));
            setRecords([]);
          },
        }
      );
    });

  const update: Update = async (action, getVariableValue) => {
    const update = async (
      newRecord: CMSCollectionRecord,
      source: VariableSource
    ) => {
      const record = await getVariableValue({
        ...source,
        fieldName: undefined,
      });
      const segments = removePrefix(record).split("/");
      const collectionId = segments.slice(0, -1).join("/");
      const recordId = segments.slice(-1)[0];
      const recordReference = firestore
        .collection(firestorePrefix + collectionId)
        .doc(recordId);
      try {
        await recordReference.update({
          ...newRecord,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } catch {
        const currentUserId = await getVariableValue({
          type: VariableSourceType.globalVariable,
          variableName: "currentUserId",
        });
        await recordReference.set({
          ...newRecord,
          ownerId: currentUserId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    };
    const create = async (
      newRecord: CMSCollectionRecord,
      collection: CollectionVariable,
      valueTarget?: VariableSource
    ) => {
      const collectionId = await getCurrentCollectionId(
        collection,
        getVariableValue
      );
      const collectionData = collections.find(
        (el) => el.name === collection.name
      );
      if (collectionData) {
        const currentUserId = await getVariableValue({
          type: VariableSourceType.globalVariable,
          variableName: "currentUserId",
        });
        const recordReference = await firestore
          .collection(firestorePrefix + collectionId)
          .add({
            ...newRecord,
            ownerId: currentUserId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        if (valueTarget) {
          const record = await recordReference.get();
          const { fieldName, variableName } = valueTarget;
          if (fieldName) {
            await update(
              { [fieldName]: { id: record.id, ...record.data() } },
              valueTarget
            );
          } else if (variableName) {
            await getVariableValue(
              { type: VariableSourceType.localVariable, variableName },
              undefined,
              undefined,
              undefined,
              {
                ...collectionData,
                records: [{ id: record.id, ...record.data() }],
              }
            );
          }
        }
      }
    };
    const getNewRecord = async (recordModifications: RecordModification[]) => {
      const newRecord: CMSCollectionRecord = {};
      for (const el of recordModifications) {
        const { fieldName, value } = el;
        if (fieldName && value) {
          newRecord[fieldName] = await getTypedValue(value);
        }
      }
      return newRecord;
    };
    const getTypedValue = async (value: AnyVariable) => {
      const { source, arrayConstant, textConstant, dateTimeConstant } = value;
      if (source) {
        const value = await getVariableValue(source);
        try {
          const obj = JSON.parse(value);
          if (!isNaN(obj)) {
            throw new Error();
          } else if (Object.keys(obj).length === 0) {
            return null;
          } else {
            return obj;
          }
        } catch {
          if (typeof value === "object") {
            return value;
          } else if (value && !isNaN(+value)) {
            return +value;
          } else {
            return value || null;
          }
        }
      } else if (arrayConstant) {
        const value = [];
        for (const el of arrayConstant) {
          if (el.source) {
            value.push(await getVariableValue(el.source));
          }
        }
        return value.length ? value : null;
      } else if (textConstant) {
        return textConstant.locales[language] || null;
      } else if (dateTimeConstant) {
        return firebase.firestore.FieldValue.serverTimestamp();
      }
    };
    const {
      valueTarget,
      collection,
      recordModifications,
      record,
      actionType,
      variable,
    } = action;
    if (actionType === ActionType.getPhoto) {
      return new Promise((resolve) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = async (e: any) => {
          const file = e.target.files[0];
          if (file) {
            const newRecord = await getAssetRecord(file);
            if (newRecord) {
              await create(newRecord, { name: "assets" }, valueTarget);
            }
          }
          resolve();
        };
        input.click();
        input.oncancel = () => resolve();
      });
    } else if (
      actionType === ActionType.createRecord &&
      recordModifications &&
      collection
    ) {
      const newRecord = await getNewRecord(recordModifications);
      await create(newRecord, collection, valueTarget);
    } else if (
      actionType === ActionType.updateRecord &&
      recordModifications &&
      record
    ) {
      const newRecord = await getNewRecord(recordModifications);
      await update(newRecord, record);
    } else if (actionType === ActionType.setValue && valueTarget && variable) {
      const valueToSet = variable.source
        ? await getVariableValue(variable.source)
        : await getTextValue(
            language,
            getVariableValue,
            undefined,
            variable.textConstant
          );
      await getVariableValue(
        valueTarget,
        undefined,
        undefined,
        undefined,
        valueToSet
      );
    }
  };

  const convertFieldToString = async (
    value: any,
    property: CollectionProperty,
    getVariableValue: GetVariableValue,
    refresh?: () => void,
    transforms?: VariableTransform[]
  ) => {
    let valueToReturn = "";
    const { type, collection } = property;
    const fieldTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.field
    );
    const formatTimeIntervalTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.formatTimeInterval
    );
    const formatTimeAgoTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.formatTimeAgo
    );
    const trimWhitespacesAndNewlinesTransform = transforms?.find(
      (el) =>
        el.transform === VariableTransformTransform.trimWhitespacesAndNewlines
    );
    const existsTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.exists
    );
    const isNotEmptyTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.isNotEmpty
    );
    if (value) {
      if (
        type === ValueType.accessLevel ||
        type === ValueType.color ||
        type === ValueType.string ||
        type === ValueType.number ||
        type === ValueType.url ||
        type === ValueType.json
      ) {
        valueToReturn = value;
      } else if (
        type === ValueType.boolean ||
        type === ValueType.array ||
        type === ValueType.coordinate ||
        type === ValueType.keyValueMap
      ) {
        valueToReturn = JSON.stringify(value);
      } else if (type === ValueType.richText) {
        valueToReturn = marked(value, { async: false }) as string;
      } else if (type === ValueType.date || type === ValueType.dateTime) {
        if (formatTimeAgoTransform && value.seconds) {
          valueToReturn = format(value.seconds * 1000);
        } else {
          valueToReturn = value;
        }
      } else if (
        type === ValueType.image ||
        type === ValueType.video ||
        type === ValueType.audio ||
        type === ValueType.file
      ) {
        if (fieldTransform && fieldTransform.fieldName) {
          valueToReturn = value[fieldTransform.fieldName];
        } else {
          valueToReturn = JSON.stringify(value);
        }
      } else if (type === ValueType.record && collection) {
        valueToReturn = value;
        if (fieldTransform && fieldTransform.fieldName) {
          const id = getRecordId(valueToReturn);
          valueToReturn =
            fieldTransform.fieldName === "id"
              ? id
              : await getVariableValue(
                  {
                    type: VariableSourceType.collection,
                    collection: { name: collection },
                    selector: { constant: id },
                    fieldName: fieldTransform.fieldName,
                  },
                  refresh
                );
        }
      }
    }
    if (formatTimeIntervalTransform && !isNaN(+valueToReturn)) {
      let time = "";
      const fullTime = new Date(+valueToReturn * 1000)
        .toISOString()
        .slice(11, 19);
      const hours = fullTime.slice(0, 2);
      time = hours === "00" ? fullTime.slice(3, 8) : fullTime;
      valueToReturn = time.startsWith("0") ? time.slice(1) : time;
    }
    if (trimWhitespacesAndNewlinesTransform) {
      valueToReturn = valueToReturn.trim();
    }
    if (existsTransform || isNotEmptyTransform) {
      valueToReturn = !!valueToReturn ? "true" : "false";
    }
    valueToReturn = await commonTransforms(
      valueToReturn,
      getVariableValue,
      refresh,
      transforms
    );
    return valueToReturn;
  };

  const convertRecordToString = async (
    record: CMSCollectionRecord,
    transforms: VariableTransform[],
    getVariableValue: GetVariableValue,
    refresh?: () => void
  ) => {
    const getParticipantName = async (
      participantId: string,
      participantNameKeys: string[]
    ) => {
      const fields: string[] = [];
      for (const fieldName of participantNameKeys) {
        fields.push(
          await getVariableValue(
            {
              type: VariableSourceType.collection,
              collection: { name: "profiles" },
              selector: { constant: participantId },
              fieldName,
            },
            refresh
          )
        );
      }
      return fields;
    };
    let valueToReturn = "";
    const conversationTitleTransform = transforms.find(
      (el) => el.transform === VariableTransformTransform.conversationTitle
    );
    const conversationImageTransform = transforms.find(
      (el) => el.transform === VariableTransformTransform.conversationImage
    );
    const identifierTransform = transforms.find(
      (el) => el.transform === VariableTransformTransform.identifier
    );
    const existsTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.exists
    );
    const isNotEmptyTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.isNotEmpty
    );
    if (conversationTitleTransform) {
      if (
        conversationTitleTransform.fieldName &&
        record[conversationTitleTransform.fieldName]
      ) {
        valueToReturn = record[conversationTitleTransform.fieldName];
      } else if (
        conversationTitleTransform.participantsKey &&
        record[conversationTitleTransform.participantsKey]?.length === 2
      ) {
        const currentUserId = await getVariableValue(
          {
            type: VariableSourceType.globalVariable,
            variableName: "currentUserId",
          },
          refresh
        );
        const participantId = record[
          conversationTitleTransform.participantsKey
        ].find((el: string) => el !== currentUserId);
        if (conversationTitleTransform.participantNameKeys) {
          valueToReturn = (
            await getParticipantName(
              participantId,
              conversationTitleTransform.participantNameKeys
            )
          ).join(" ");
        }
      }
    }
    if (conversationImageTransform) {
      if (
        conversationImageTransform.fieldName &&
        record[conversationImageTransform.fieldName]?.url
      ) {
        valueToReturn = record[conversationImageTransform.fieldName].url;
      } else if (
        conversationImageTransform.participantsKey &&
        record[conversationImageTransform.participantsKey]?.length === 2
      ) {
        const currentUserId = await getVariableValue(
          {
            type: VariableSourceType.globalVariable,
            variableName: "currentUserId",
          },
          refresh
        );
        const participantId = record[
          conversationImageTransform.participantsKey
        ].find((el: string) => el !== currentUserId);
        if (conversationImageTransform.participantImageKey) {
          valueToReturn = await getVariableValue(
            {
              type: VariableSourceType.collection,
              collection: { name: "profiles" },
              selector: { constant: participantId },
              fieldName: conversationImageTransform.participantImageKey,
            },
            refresh
          );
          if (
            !valueToReturn &&
            conversationImageTransform.participantNameKeys
          ) {
            valueToReturn = createAvatar(
              (
                await getParticipantName(
                  participantId,
                  conversationImageTransform.participantNameKeys
                )
              )
                .map((el) => el[0] || "")
                .join("")
            );
          }
        }
      }
    }
    if (identifierTransform && record.id) {
      valueToReturn = record.id;
    }
    if (existsTransform || isNotEmptyTransform) {
      valueToReturn = record.id ? "true" : "false";
    }
    valueToReturn = await commonTransforms(
      valueToReturn,
      getVariableValue,
      refresh,
      transforms
    );
    return valueToReturn;
  };

  const commonTransforms = async (
    valueToReturn: string,
    getVariableValue: GetVariableValue,
    refresh?: () => void,
    transforms?: VariableTransform[]
  ) => {
    const anyExistsTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.anyExists
    );
    const compareEqualTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.compareEqual
    );
    const compareNotEqualTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.compareNotEqual
    );
    const boolNotTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.boolNot
    );
    const conditionalValueTransform = transforms?.find(
      (el) => el.transform === VariableTransformTransform.conditionalValue
    );
    if (anyExistsTransform) {
      try {
        const values = JSON.parse(valueToReturn) as any[];
        valueToReturn = values.filter((el) => !!el).length ? "true" : "false";
      } catch {
        valueToReturn = "false";
      }
    }
    if (compareEqualTransform) {
      if (compareEqualTransform.value?.source) {
        valueToReturn =
          valueToReturn ===
          (await getVariableValue(compareEqualTransform.value.source, refresh))
            ? "true"
            : "false";
      } else if (compareEqualTransform.value?.calendarStyleConstant) {
        valueToReturn =
          valueToReturn === compareEqualTransform.value.calendarStyleConstant
            ? "true"
            : "false";
      }
    }
    if (compareNotEqualTransform) {
      if (compareNotEqualTransform.value?.source) {
        valueToReturn =
          valueToReturn !==
          (await getVariableValue(
            compareNotEqualTransform.value.source,
            refresh
          ))
            ? "true"
            : "false";
      }
    }
    if (boolNotTransform) {
      valueToReturn = valueToReturn === "false" ? "true" : "false";
    }
    if (conditionalValueTransform) {
      if (
        conditionalValueTransform.value?.colorConstant &&
        conditionalValueTransform.value2?.colorConstant
      ) {
        const {
          value: { colorConstant: cC1 },
          value2: { colorConstant: cC2 },
        } = conditionalValueTransform;
        valueToReturn = valueToReturn === "true" ? cC1 : cC2;
      } else if (
        conditionalValueTransform.value?.imageConstant &&
        conditionalValueTransform.value2?.imageConstant
      ) {
        const {
          value: {
            imageConstant: { resourceId: rId1 },
          },
          value2: {
            imageConstant: { resourceId: rId2 },
          },
        } = conditionalValueTransform;
        valueToReturn =
          valueToReturn === "true"
            ? resources.find((el) => el.id === rId1)?.url || ""
            : resources.find((el) => el.id === rId2)?.url || "";
      } else if (
        conditionalValueTransform.value?.source &&
        conditionalValueTransform.value2?.imageConstant
      ) {
        const {
          value: { source },
          value2: {
            imageConstant: { resourceId },
          },
        } = conditionalValueTransform;
        valueToReturn =
          valueToReturn === "true"
            ? await getVariableValue(source, refresh)
            : resources.find((el) => el.id === resourceId)?.url || "";
      } else if (
        conditionalValueTransform.value?.textConstant &&
        conditionalValueTransform.value2?.source
      ) {
        const {
          value: { textConstant },
          value2: { source },
        } = conditionalValueTransform;
        valueToReturn =
          valueToReturn === "true"
            ? await getTextValue(
                language,
                getVariableValue,
                refresh,
                textConstant
              )
            : await getVariableValue(source, refresh);
      } else if (
        conditionalValueTransform.value?.source &&
        conditionalValueTransform.value2?.source
      ) {
        const {
          value: { source: s1 },
          value2: { source: s2 },
        } = conditionalValueTransform;
        valueToReturn =
          valueToReturn === "true"
            ? await getVariableValue(s1, refresh)
            : await getVariableValue(s2, refresh);
      }
    }
    return valueToReturn;
  };

  useEffect(() => {
    variables = {};
    firebaseDatasources = {};
    Object.keys(snapshots).forEach((el) => {
      snapshots[el]();
      delete snapshots[el];
    });
  }, [auth.currentUser]);

  useEffect(() => {
    return () => {
      Object.keys(refreshes).forEach((el) => delete refreshes[el]);
      screen?.inputParameters?.forEach(
        (el) => delete variables[screen.id + el.parameter]
      );
      screen?.localVariables?.forEach(
        (el) => delete variables[screen.id + el.name]
      );
    };
  }, [searchParams, inputParameterValue, screen]);

  return { getVariable, update };
};

export const collectionPrefix = "collection://";

export const removePrefix = (recordPath: string) =>
  recordPath.replace(collectionPrefix, "");

export const getRecordId = (recordPath: string) =>
  recordPath.split("/").slice(-1)[0];

const getCurrentCollectionId = async (
  collection: CollectionVariable,
  getVariableValue: GetVariableValue,
  refresh?: () => void
) => {
  let collectionId = collection.name;
  if (collection.params) {
    for (const el of Object.keys(collection.params)) {
      collectionId = collectionId.replace(
        `{${el}}`,
        await getVariableValue(collection.params[el], refresh)
      );
    }
  }
  return collectionId;
};

const hasMutableFilter = (query?: TableQuery) =>
  !!query?.filters?.find((el) => {
    const type = el.value.source?.type;
    return type
      ? [
          VariableSourceType.collection,
          VariableSourceType.localVariable,
          VariableSourceType.component,
        ].includes(type)
      : false;
  });

const createAvatar = (initials: string) => {
  const randomColor = () =>
    "#" + (0x1000000 | (Math.random() * 0xffffff)).toString(16).substr(1, 6);
  const canvas = document.createElement("canvas");
  canvas.width = 100;
  canvas.height = 100;
  const context = canvas.getContext("2d");
  if (context) {
    context.fillStyle = randomColor();
    context.beginPath();
    context.ellipse(
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
      canvas.height / 2,
      0,
      0,
      Math.PI * 2
    );
    context.fill();
    context.font = "60px serif";
    context.fillStyle = randomColor();
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(initials, canvas.width / 2, canvas.height / 2);
  }
  return canvas.toDataURL();
};

export const setLocalVariables = async (
  getVariableValue: GetVariableValue,
  localVariables?: LocalVariable[],
  searchParams?: URLSearchParams,
  inputParameterValue?: string,
  inputParameters?: ScreenParameter[]
) => {
  if (inputParameters) {
    for (const el of inputParameters) {
      const { parameter, inout, type, collection } = el;
      if (inout) {
        if (type === ValueType.string || type === ValueType.number) {
          const valueToSet = searchParams?.get(parameter) || "";
          await getVariableValue(
            { type: VariableSourceType.localVariable, variableName: parameter },
            undefined,
            undefined,
            undefined,
            valueToSet
          );
        }
      } else if (inputParameterValue) {
        if (type === ValueType.record && collection) {
          await getVariableValue(
            {
              selector: { constant: inputParameterValue },
              collection: { name: collection },
              type: VariableSourceType.collection,
            },
            undefined,
            undefined,
            parameter
          );
        } else if (type === ValueType.string || type === ValueType.number) {
          await getVariableValue(
            { type: VariableSourceType.localVariable, variableName: parameter },
            undefined,
            undefined,
            undefined,
            inputParameterValue
          );
        }
      }
    }
  }
  if (localVariables) {
    for (const el of localVariables) {
      const { name, variable, type, collection } = el;
      if (variable?.source) {
        await getVariableValue(variable.source, undefined, undefined, name);
      } else if (variable?.arrayConstant) {
        const values = [];
        for (const el of variable.arrayConstant) {
          if (el.source) {
            values.push(await getVariableValue(el.source));
          }
        }
        await getVariableValue(
          { type: VariableSourceType.localVariable, variableName: name },
          undefined,
          undefined,
          undefined,
          JSON.stringify(values)
        );
      } else if (variable?.calendarStyleConstant) {
        await getVariableValue(
          { type: VariableSourceType.localVariable, variableName: name },
          undefined,
          undefined,
          undefined,
          variable.calendarStyleConstant
        );
      } else if (type === ValueType.record && collection) {
        await getVariableValue(
          {
            collection: { name: collection },
            type: VariableSourceType.collection,
          },
          undefined,
          undefined,
          name
        );
      } else if (
        type === ValueType.image ||
        type === ValueType.video ||
        type === ValueType.audio ||
        type === ValueType.file
      ) {
        await getVariableValue(
          {
            collection: { name: "assets" },
            type: VariableSourceType.collection,
          },
          undefined,
          undefined,
          name
        );
      }
    }
  }
};

export const getParams = async (
  action: Action,
  getVariableValue: GetVariableValue
) => {
  const params: { [key: string]: string } = {};
  const { actionArguments } = action;
  if (actionArguments) {
    for (const el of actionArguments) {
      const { name, value } = el;
      if (value.source) {
        params[name] = await getVariableValue(value.source);
      }
    }
  }
  return params;
};
