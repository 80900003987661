import {
  BrandingColorStyles,
  BrandingThemeType,
  ComponentStyle,
} from "./index";

const getArrow = (
  element: HTMLElement,
  onSurfaceVariant: string,
  right?: boolean
) => {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("viewBox", "0 0 11 24");
  svg.setAttribute("fill", "none");
  svg.innerHTML = `<path d="M9 2L2 12L9 22" stroke="${onSurfaceVariant}" stroke-width="3" stroke-linecap="round"/>`;
  svg.style.width = "10px";
  svg.style.height = "100%";
  svg.style.padding = "0 5px";
  svg.style.zIndex = "1";
  svg.style.position = "absolute";
  svg.style.top = "0";
  svg.style[right ? "right" : "left"] = "0";
  svg.style.transform = `rotate(${right ? 180 : 0}deg)`;
  svg.classList.add("clickable");
  svg.onclick = () => {
    element.scrollLeft = right
      ? element.scrollLeft + element.clientWidth
      : element.scrollLeft - element.clientWidth;
    const scrollEnd = () => {
      svg.parentNode?.dispatchEvent(new Event("mouseover"));
      element.removeEventListener("scrollend", scrollEnd);
    };
    element.addEventListener("scrollend", scrollEnd);
  };
  return svg;
};

export const setComponentStyle = (
  element: HTMLElement,
  componentStyle: ComponentStyle,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  desktopMode: boolean
) => {
  element.className = componentStyle;
  if (
    desktopMode &&
    (componentStyle === ComponentStyle.row ||
      componentStyle === ComponentStyle.swipeable)
  ) {
    element.style.scrollBehavior = "smooth";
    element.style.margin = element.style.padding;
    element.style.removeProperty("padding");
    const listWrapper = document.createElement("div");
    listWrapper.style.maxWidth = "100%";
    listWrapper.style.position = "relative";
    element.parentNode?.replaceChild(listWrapper, element);
    listWrapper.appendChild(element);
    const onSurfaceVariant = colorStyles.onSurfaceVariant[theme];
    const leftArrow = getArrow(element, onSurfaceVariant);
    const rightArrow = getArrow(element, onSurfaceVariant, true);
    listWrapper.onmouseover = () => {
      const { scrollLeft, clientWidth, scrollWidth } = element;
      if (scrollLeft > 0) {
        listWrapper.appendChild(leftArrow);
      } else {
        leftArrow.parentNode?.removeChild(leftArrow);
      }
      if (Math.round(scrollLeft + clientWidth) < scrollWidth) {
        listWrapper.appendChild(rightArrow);
      } else {
        rightArrow.parentNode?.removeChild(rightArrow);
      }
    };
    listWrapper.onmouseleave = () => {
      leftArrow.parentNode?.removeChild(leftArrow);
      rightArrow.parentNode?.removeChild(rightArrow);
    };
  }
};
