import firebase from "firebase/compat/app";
import { createContext } from "react";

import { GetVariableValue } from "../hooks";
import { Action } from "../utils";

export type FF = (
  action: Action,
  getVariableValue: GetVariableValue
) => Promise<void>;

export interface F {
  firestorePrefix: string;
  auth: firebase.auth.Auth;
  firestore: firebase.firestore.Firestore;
  signInWithGoogle: () => Promise<void>;
  createUserWithEmailAndPassword: FF;
  signInWithEmailAndPassword: FF;
  sendPasswordResetEmail: FF;
  signInWithPhoneNumber: FF;
  reSignInWithPhoneNumber: FF;
  confirmPhoneNumber: FF;
  sendSignInLinkToEmail: FF;
  reSendSignInLinkToEmail: FF;
  getAssetRecord: (file: any) => Promise<unknown>;
  emailForSignInKey: string;
}

export const FirebaseContext = createContext({
  f: {
    firestorePrefix: "",
    auth: {},
    firestore: {},
    signInWithGoogle: () => {},
    createUserWithEmailAndPassword: (action, getVariableValue) => {},
    signInWithEmailAndPassword: (action, getVariableValue) => {},
    sendPasswordResetEmail: (action, getVariableValue) => {},
    signInWithPhoneNumber: (action, getVariableValue) => {},
    reSignInWithPhoneNumber: (action, getVariableValue) => {},
    confirmPhoneNumber: (action, getVariableValue) => {},
    sendSignInLinkToEmail: (action, getVariableValue) => {},
    reSendSignInLinkToEmail: (action, getVariableValue) => {},
    getAssetRecord: (file) => {},
    emailForSignInKey: "",
  } as F,
});

export * from "./FirebaseProvider";
